import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { keyframes } from '@emotion/react';
import { css } from '@emotion/css';

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const FriendsPage = ({ initData }) => {
  const [userAvatar, setUserAvatar] = useState('');
  const [userName, setUserName] = useState('');
  const [referrals, setReferrals] = useState([]);
  const [visibleReferrals, setVisibleReferrals] = useState([]);
  const [referralLink, setReferralLink] = useState('');
  const [balance, setBalance] = useState({ coins: 0 });
  const defaultUserId = 5776632354;

  useEffect(() => {
    const user = initData && initData.user ? initData.user : { id: defaultUserId, username: 'User', photo_url: '' };
    setUserAvatar(user.photo_url);
    setUserName(user.username || 'User');
    const userId = user.id;

    // Set referral link
    const link = `https://t.me/MineXCryptobot?start=${userId}`;
    setReferralLink(link);

    // Fetch balance
    fetch(`https://api.minex.space/balance/${userId}`)
      .then(response => response.json())
      .then(data => setBalance(data))
      .catch(error => console.error('Error fetching balance:', error));

    // Fetch referrals
    fetch(`https://api.minex.space/referrals/${userId}`)
      .then(response => response.json())
      .then(data => {
        if (data.first_level) {
          const formattedReferrals = data.first_level.map(referral => ({
            ...referral,
            secondLevel: data.second_level.filter(sl => sl.referral_id === referral.user_id),
          }));
          setReferrals(formattedReferrals);
        } else {
          console.error('Unexpected data format:', data);
        }
      })
      .catch(error => console.error('Error fetching referrals:', error));
  }, [initData]);

  useEffect(() => {
    let delay = 0;
    const intervals = referrals.map((_, index) => setTimeout(() => {
      setVisibleReferrals(prev => [...prev, referrals[index]]);
    }, delay += 200));
    return () => intervals.forEach(clearTimeout);
  }, [referrals]);

  return (
    <Box
      sx={{
        position: 'fixed',
        width: '80%',
        height: '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        padding: '20px',
        borderRadius: '10px',
        color: 'white',
        textAlign: 'center',
        zIndex: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>{userName}'s Referral Tree</Typography>
      <Box sx={{ mt: 2, width: '100%', textAlign: 'left', overflowY: 'auto', height: 'calc(100% - 150px)' }}>
        {visibleReferrals.map((referral, index) => (
          <Box 
            key={referral.user_id} 
            className={css`
              animation: ${slideUp} 0.5s ease-in-out forwards;
              animation-delay: ${index * 0.2}s;
            `}
            sx={{ mb: 2, opacity: 0 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography sx={{ fontSize: '16px', color: 'white' }}>{referral.username}</Typography>
              <AccountBalanceWalletIcon sx={{ color: 'gold', ml: 1 }} />
              <Typography sx={{ fontSize: '14px', color: 'white', ml: 1 }}>{referral.coin_balance} Coins</Typography>
              <Typography sx={{ fontSize: '14px', color: 'white', ml: 1 }}>Earned: {referral.earnings} Coins</Typography>
            </Box>
            <Box sx={{ ml: 4 }}>
              {referral.secondLevel.map((slReferral, slIndex) => (
                <Box 
                  key={slReferral.user_id} 
                  className={css`
                    animation: ${slideUp} 0.5s ease-in-out forwards;
                    animation-delay: ${index * 0.2 + slIndex * 0.1}s;
                  `}
                  sx={{ display: 'flex', alignItems: 'center', mb: 1, opacity: 0 }}
                >
                  <Typography sx={{ fontSize: '14px', color: 'lightgray' }}>{slReferral.username}</Typography>
                  <AccountBalanceWalletIcon sx={{ color: 'lightgray', ml: 1 }} />
                  <Typography sx={{ fontSize: '12px', color: 'lightgray', ml: 1 }}>{slReferral.coin_balance} Coins</Typography>
                  <Typography sx={{ fontSize: '12px', color: 'lightgray', ml: 1 }}>Earned: {slReferral.earnings} Coins</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      
      <Box sx={{ mt: 2, width: '100%', textAlign: 'left' }}>
        <TextField
          label="Referral Link"
          value={referralLink}
          variant="outlined"
          fullWidth
          InputProps={{
            readOnly: true,
            style: { color: 'white' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'white',
            },
          }}
        />
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 3 }}>
  <IconButton
    color="secondary"
    href={`https://t.me/share/url?url=${referralLink}&text=Join%20MineX%20using%20my%20referral%20link!`}
    sx={{ fontSize: 40 }}
  >
    <ShareIcon fontSize="inherit" />
  </IconButton>
  <IconButton
    color="primary"
    onClick={() => navigator.clipboard.writeText(referralLink)}
    sx={{ fontSize: 40 }}
  >
    <ContentCopyIcon fontSize="inherit" />
  </IconButton>
</Box>

      
    </Box>
  );
};

export default FriendsPage;
