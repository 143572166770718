import React from 'react';
import { Box, Typography } from '@mui/material';
import loaderImage from './load.jpg'; // Replace with the actual path to your image
import overlaySvg from './Frame 7.svg'; // Replace with the actual path to your SVG file

const Loader = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'black',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    }}
  >
    <Box
      sx={{
        width: '300px',
        height: '300px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
      }}
    >
      {/* Overlaying SVG */}
      <Box
        component="img"
        src={overlaySvg}
        alt="Overlay"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}
      />
    </Box>
    
    <style jsx global>{`
      @keyframes dots {
        0%, 20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: lightblue;
          text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: .25em 0 0 lightblue, .5em 0 0 rgba(0, 0, 0, 0);
        }
        80%, 100% {
          text-shadow: .25em 0 0 lightblue, .5em 0 0 lightblue;
        }
      }
    `}</style>
  </Box>
);

export default Loader;
