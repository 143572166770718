import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, grey } from '@mui/material/colors';
import logoImage from './static/Logo_txt.png';
import gameImage from './static/game_image.png'; // Імпортуємо картинку
import backgroundImage from './static/Bg.svg'; // Імпортуємо задній фон
import leftIcon from './static/Frame 1509.svg'; // Імпортуємо іконку для боків
import rightIcon from './static/Frame 1508.svg'; // Імпортуємо іконку для боків
import transitionImage from './static/Mask group.png'; // Імпортуємо зображення для переходу

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: grey,
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

const GamesPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="sm"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          color: 'white',
          textAlign: 'center',
          overflow: 'hidden',
          padding: 0,
          margin: 0,
          backgroundImage: `url(${backgroundImage})`, // Додаємо задній фон
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 4,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '50%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${gameImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Box
            component="img"
            src={logoImage}
            alt="Logo"
            sx={{
              width: '140px',
              height: 'auto',
              position: 'absolute',
              top: '20px',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: '100px',
              backgroundImage: `url(${transitionImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'bottom',
            }}
          />
        </Box>
        <Box sx={{ marginTop: '20px', width: '80%' }}>
          <Typography variant="h6" sx={{ fontSize: '12px' }}>
            WORK IN PROGRESS
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <Box component="img" src={leftIcon} alt="Side Icon" sx={{ width: '54px', height: '24px', marginRight: '10px' }} />
          <Typography variant="h4" sx={{ fontSize: '20px' }}>
            Games
          </Typography>
          <Box component="img" src={rightIcon} alt="Side Icon" sx={{ width: '54px', height: '24px', marginLeft: '10px' }} />
        </Box>
        <Box sx={{ marginTop: '20px', width: '80%' }}>
          <Typography variant="h6" sx={{ marginBottom: '10px', fontSize: '12px' }}>
            WORK IN PROGRESS
          </Typography>
          <Typography variant="body1">
            This section will feature games that you can play with people from the future. Play to win extra coins.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default GamesPage;
