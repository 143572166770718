import React from 'react';
import { Box, Container, Typography, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, grey } from '@mui/material/colors';
import logoImage from './static/Logo_txt.png';
import itemImage from './static/item.svg'; // Імпортуємо зображення предмета
import addItemIcon from './static/add_item.svg'; // Імпортуємо іконку додавання предмета
import leftArrow from './static/left.svg'; // Імпортуємо іконку лівої стрілки
import rightArrow from './static/right.svg'; // Імпортуємо іконку правої стрілки
import backgroundImage from './static/Bg.svg'; // Імпортуємо задній фон
import leftIcon from './static/Frame 1509.svg'; // Імпортуємо іконку для боків
import rightIcon from './static/Frame 1508.svg'; // Імпортуємо іконку для боків


const theme = createTheme({
  palette: {
    primary: blue,
    secondary: grey,
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

const GaragePage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="sm"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          textAlign: 'center',
          overflow: 'hidden',
          padding: 0,
          margin: 0,
          backgroundColor: 'rgba(21, 26, 48, 1)',
            backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 4,
        }}
      >
        <Box
          component="img"
          src={logoImage}
          alt="Logo"
          sx={{
            width: '60vw',
            height: 'auto',
          }}
        />
         <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <Box component="img" src={leftIcon} alt="Side Icon" sx={{ width: '54px', height: '24px', marginRight: '10px' }} />
          <Typography variant="h4" sx={{ fontSize: '20px' }}>
            Garage
          </Typography>
          <Box component="img" src={rightIcon} alt="Side Icon" sx={{ width: '54px', height: '24px', marginLeft: '10px' }} />
        </Box>
        <Typography variant="body1" sx={{ marginTop: '10px', fontSize: '10px', width: '80%' }}>
          Of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridGap: '10px',
            marginTop: '20px',
            width: 'auto',
            height: 'auto',
            justifyContent: 'center', // Центруємо сітку по горизонталі
          }}
        >
          {[...Array(9)].map((_, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                border: index === 0 ? '2px solid #00BFFF' : '2px dashed #00BFFF',
                borderRadius: '10px',
                padding: '10px',
                height: '80px',
                width: '80px',
              }}
            >
              {index === 4 ? (
                <>
                  <Typography variant="h6" sx={{ fontSize: '10px', fontWeight: 'bold' }}>
                    Productivity
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: '18px', fontWeight: 'bold', marginTop: '10px' }}>
                    1.0 SPHR/HOUR
                  </Typography>
                </>
              ) : (
                <Box
                  component="img"
                  src={index === 0 ? itemImage : addItemIcon}
                  alt="Item"
                  sx={{
                    width: '100%',
                    height: 'auto',
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <IconButton sx={{ width: '40px', height: '40px' }}>
            <Box component="img" src={leftArrow} alt="Left Arrow" sx={{ width: '100%', height: 'auto' }} />
          </IconButton>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px' }}>
            <Typography variant="body1" sx={{ margin: '0 5px' }}>•</Typography>
            <Typography variant="body1" sx={{ margin: '0 5px' }}>•</Typography>
            <Typography variant="body1" sx={{ margin: '0 5px' }}>•</Typography>
          </Box>
          <IconButton sx={{ width: '40px', height: '40px' }}>
            <Box component="img" src={rightArrow} alt="Right Arrow" sx={{ width: '100%', height: 'auto' }} />
          </IconButton>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default GaragePage;
