import React, { useEffect, useState } from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import { Container, Box, Typography, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, grey } from '@mui/material/colors';
import SpherePage from './SpherePage';
import FriendsPage from './FriendsPage';
import Loader from './Loader';
import AboutUsPage from './AboutUsPage';
import AccountPage from './AccountPage';
import StorePage from './StorePage';
import TaskPage from './TaskPage'; // Додайте ці компоненти
import GaragePage from './GaragePage'; // Додайте ці компоненти
import LeaderBoardPage from './LeaderBoardPage'; // Додайте ці компоненти
import GamesPage from './GamesPage'; // Додайте ці компоненти
import bottombar from './static/bottombar.png';
import store_off from './static/store_off.png';
import store_on from './static/store_on.png';

import task_off from './static/task_off.png';
import task_on from './static/task_on.png';

import leader_off from './static/leader_off.png';
import leader_on from './static/leader_on.png';

import garage_off from './static/garage_off.png';
import garage_on from './static/garage_on.png';
import main_on from './static/main_on.png';
import main_off from './static/main_off.png';
import friends_off from './static/friends_off.png';
import friends_on from './static/friends_on.png';
import about_on from './static/about_on.png';
import about_off from './static/ABOUT_off.png';
import account_icon from './static/account_icon.png'; 
import store_icon from './static/store_icon.png'; 
import logoImage from './static/Logo_txt.png';
import overlaySvg1 from './static/Bg.svg';
import overlaySvg2 from './static/Stars.svg';
import initReactFastclick from 'react-fastclick';
import './App.css';
import { TonConnectButton, useTonAddress } from '@tonconnect/ui-react';
import { retrieveLaunchParams } from '@tma.js/sdk';
import fonnrg from './static/Borders.png';
import energy from './static/NRG.png';

initReactFastclick();

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: grey,
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

const TonAddress = () => {
  const userFriendlyAddress = useTonAddress();
  const rawAddress = useTonAddress(false);

  return (
    <div>
      {userFriendlyAddress && <span>User-friendly address: {userFriendlyAddress}</span>}
      {rawAddress && <span>Raw address: {rawAddress}</span>}
    </div>
  );
};

const EnergyBar = ({ balance }) => {
  const progress = Math.min(balance.coins / 1000, 1) * 100;

  const segments = [];
  for (let i = 1; i <= 50; i++) {
    const isFilled = progress >= (i / 50) * 100;
    let height = 32;
    if (i === 48) height = 28;
    if (i === 49) height = 24;
    if (i === 50) height = 20;
    segments.push(
      <Box
        key={i}
        sx={{
          width: '2%',
          height: `${height}px`,
          backgroundColor: isFilled ? '#FFC107' : '#3D3D3D',
          marginRight: '2px',
          alignSelf: 'flex-start',
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '90px',
        width: '90%',
        height: '8vh',
        backgroundColor: '#1A1A1A',
        backgroundImage: `url(${fonnrg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '0.5vh',
        borderRadius: '2vh',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          width: '2vw',
          height: '2vw',
          backgroundColor: '#FFC107',
          zIndex: 1,
        },
        '&::before': {
          top: 0,
          left: 0,
          clipPath: 'polygon(0 0, 100% 0, 0 100%)',
        },
        '&::after': {
          bottom: 0,
          right: 0,
          clipPath: 'polygon(100% 0, 100% 100%, 0 100%)',
        },
      }}
    >
      <Box component="img" src={energy} alt="Energy Icon" sx={{ width: '10vw', height: 'auto', marginRight: '2vw' }} />
      <Box sx={{ marginRight: '2vw' }}>
        <Typography variant="body2" sx={{ color: 'white', fontFamily: 'Orbitron, sans-serif', fontWeight: 'bold' }}>
          ENERGY
        </Typography>
        <Typography variant="body2" sx={{ color: '#FFC107', fontFamily: 'Orbitron, sans-serif', fontWeight: 'bold' }}>
          {balance.coins.toFixed(0)}
          <Typography
            variant="body2"
            component="span"
            sx={{ fontSize: '0.8em', color: ' #E89505;', verticalAlign: 'super', marginLeft: '0.5vw' }}
          >
            /1000
          </Typography>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '60%' }}>
        {segments}
      </Box>
    </Box>
  );
};

const App = () => {
  const [initData, setInitData] = useState(null);
  const [balance, setBalance] = useState({ coins: 0, nrg: 0 });
  const [loading, setLoading] = useState(true);
  const [clickEffect, setClickEffect] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const defaultUserId = 5776632354;
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('/sphere');

  const animationDuration = 0.2;

  useEffect(() => {
    const tg = window.Telegram.WebApp || {};
    tg.ready && tg.ready();
    setInitData(tg.initDataUnsafe || { user: { id: defaultUserId } });

    const userId = tg.initDataUnsafe?.user?.id || defaultUserId;
    fetch(`https://api.minex.space/balance/${userId}`)
      .then(response => response.json())
      .then(data => {
        setBalance(data);
      });

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const handleMouseDown = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = (e.clientX || e.touches[0].clientX) - rect.left;
    const y = (e.clientY || e.touches[0].clientY) - rect.top;

    setClickPosition({ x, y });

    if (navigator.vibrate) {
      navigator.vibrate(40);
    }

    setClickEffect(true);

    setTimeout(() => {
      setClickEffect(false);
    }, animationDuration * 1000);

    if (balance.coins >= 1) {
      const userId = balance.user_id || defaultUserId;
      fetch(`https://api.minex.space/balance/${userId}/mine`, {
        method: 'POST',
      })
        .then(response => response.json())
        .then(data => setBalance(data));
    }
  };

  const handleMouseUp = () => {
    setClickEffect(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {loading && <Loader />}
      {!loading && (
        <Container
          maxWidth="sm"
          className="fade-in"
          sx={{
            position: 'relative',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textAlign: 'center',
            overflow: 'hidden',
            padding: 0,
            margin: 0,
            backgroundImage: `radial-gradient(91.6666639347872% 50.000000000000014% at 49.99999453624107% 49.999999999999986%, #2a2c43 0%, rgba(41, 23, 37, 1) 100%)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Box
            component={Link}
            to="/account"
            sx={{
              position: 'absolute',
              top: '3%',
              left: '5%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: 'white',
              textDecoration: 'none',
              zIndex: 3,
            }}
          >
            <Box component="img" src={account_icon} alt="Account" sx={{ width: '32px', height: '32px', marginBottom: '4px' }} />
            <Typography variant="body2">Account</Typography>
          </Box>
          <Box
            component={Link}
            to="/store"
            sx={{
              position: 'absolute',
              top: '3%',
              right: '5%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: 'white',
              textDecoration: 'none',
              zIndex: 3,
            }}
          >
            <Box component="img" src={store_icon} alt="Store" sx={{ width: '32px', height: '32px', marginBottom: '4px' }} />
            <Typography variant="body2">Store</Typography>
          </Box>

          <Box
            component="img"
            src={logoImage}
            alt="Logo"
            sx={{
              width: '60vw',
              height: '3vh',
              position: 'absolute',
              top: '5%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 3,
            }}
          />
          <Box
            component="img"
            src={overlaySvg1}
            alt="Overlay 1"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
            }}
          />
          {/* <Box
            component="img"
            src={overlaySvg2}
            alt="Overlay 2"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
            }}
          /> */}

          <Routes>
            <Route
              path="/sphere"
              element={
                <SpherePage
                  balance={balance}
                  setBalance={setBalance}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  clickEffect={clickEffect}
                  clickPosition={clickPosition}
                  userId={initData?.user?.id || defaultUserId}
                />
              }
            />
            <Route path="/friends" element={<FriendsPage initData={initData} />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/account" element={<AccountPage />} /> 
            <Route path="/store" element={<StorePage />} /> 
            <Route path="/task" element={<TaskPage />} /> 
            <Route path="/garage" element={<GaragePage />} /> 
            <Route path="/leaderboard" element={<LeaderBoardPage />} /> 
            <Route path="/games" element={<GamesPage />} /> 
            <Route
              path="/"
              element={
                <SpherePage
                  balance={balance}
                  setBalance={setBalance}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  clickEffect={clickEffect}
                  clickPosition={clickPosition}
                  userId={initData?.user?.id || defaultUserId}
                />
              }
            />
          </Routes>
          {activeTab === '/sphere' && <EnergyBar balance={balance} />}
          <BottomNavigation
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            sx={{
              position: 'fixed',
              bottom: 0,
              width: '95vw',
              height: '82px',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              backgroundImage: `url(${bottombar})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            showLabels
          >
            <BottomNavigationAction
              component={Link}
              to="/task"
              label="Task"
              value="/task"
              sx={{ color: 'white', minWidth: '64px', padding: '6px 12px' }}
              icon={<Box component="img" src={activeTab === '/task' ? task_on : task_off} alt="Task" sx={{ width: activeTab === '/task' ? 32 : 24, height: activeTab === '/task' ? 32 : 24 }} />}
            />
            <BottomNavigationAction
              component={Link}
              to="/garage"
              label="Garage"
              value="/garage"
              sx={{ color: 'white', minWidth: '64px', padding: '6px 12px' }}
              icon={<Box component="img" src={activeTab === '/garage' ? garage_on : garage_off} alt="Garage" sx={{ width: activeTab === '/garage' ? 32 : 24, height: activeTab === '/garage' ? 32 : 24 }} />}
            />
            <BottomNavigationAction
              component={Link}
              to="/sphere"
              label="Sphere"
              value="/sphere"
              sx={{ color: 'white', minWidth: '64px', padding: '6px 12px' }}
              icon={<Box component="img" src={activeTab === '/sphere' ? main_on : main_off} alt="Sphere" sx={{ width: activeTab === '/sphere' ? 32 : 24, height: activeTab === '/sphere' ? 32 : 24 }} />}
            />
            <BottomNavigationAction
              component={Link}
              to="/leaderboard"
              label="LeaderBoard"
              value="/leaderboard"
              sx={{ color: 'white', minWidth: '64px', padding: '6px 12px' }}
              icon={<Box component="img" src={activeTab === '/leaderboard' ? leader_on : leader_off} alt="LeaderBoard" sx={{ width: activeTab === '/leaderboard' ? 32 : 24, height: activeTab === '/leaderboard' ? 32 : 24 }} />}
            />
            <BottomNavigationAction
              component={Link}
              to="/games"
              label="Games"
              value="/games"
              sx={{ color: 'white', minWidth: '64px', padding: '6px 12px' }}
              icon={<Box component="img" src={activeTab === '/games' ? about_on : about_off} alt="Games" sx={{ width: activeTab === '/games' ? 32 : 24, height: activeTab === '/games' ? 32 : 24 }} />}
            />
          </BottomNavigation>
        </Container>
      )}
    </ThemeProvider>
  );
};

export default App;
