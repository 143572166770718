import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { TonConnectUIProvider } from '@tonconnect/ui-react'; // Import the TonConnectUIProvider
import initReactFastclick from 'react-fastclick';
initReactFastclick();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <TonConnectUIProvider
      manifestUrl="https://xkd3th.csb.app/tonmanifest.json"
      actionsConfiguration={{
        twaReturnUrl: "https://t.me/lulustestbot",
      }}
    >
    <WebAppProvider>
      <Router>
        <App />
      </Router>
    </WebAppProvider>
  </TonConnectUIProvider>
);
