import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import image1 from './1.png'; // Замініть на фактичний шлях до зображення
import image2 from './2.png'; // Замініть на фактичний шлях до зображення
import image3 from './3.png'; // Замініть на фактичний шлях до зображення
import image4 from './4.png'; // Замініть на фактичний шлях до зображення
import overlaySvg1 from './Pattern.svg'; // Замініть на фактичний шлях до SVG
import overlaySvg2 from './static/Stars.svg'; // Замініть на фактичний шлях до SVG

const theme = createTheme({
  palette: {
    primary: red,
  },
  typography: {
    fontFamily: 'Courier New, monospace',
  },
});

const AboutUsPage = () => {
  const items = [
    { img: image1, text: 'Text for first image', imgFirst: true },
    { img: image2, text: 'Text for second image', imgFirst: false },
    { img: image3, text: 'Text for third image', imgFirst: true },
    { img: image4, text: 'Text for fourth image', imgFirst: false },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          color: 'white',
          backgroundImage: `radial-gradient(91.6666639347872% 50.000000000000014% at 49.99999453624107% 49.999999999999986%, #2a2c43 0%, rgba(41, 23, 37, 1) 100%)`,
          overflowY: 'auto',
          scrollbarWidth: 'none', // Hide scrollbar for Firefox
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Chrome, Safari, and Opera
        }}
      >
        {/* SVG Backgrounds */}
        <Box
          component="img"
          src={overlaySvg1}
          alt="Overlay 1"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            zIndex: 1,
          }}
        />
        <Box
          component="img"
          src={overlaySvg2}
          alt="Overlay 2"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            padding: '20px',
            textAlign: 'center',
          }}
        >
          <Typography variant="h3" sx={{ marginBottom: '20px', color: '#b0b0ff' }}>
            Minex AI MetaVerse
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '40px', color: '#b0b0ff', fontSize: "16px", fontWeight: 'bold' }}>
            Lorem Ipsum is simply dummy text
            of the printing and typesetting
            industry. Lorem Ipsum has been the
            industry's standard dummy text
            ever since the 1500s, when an
            unknown printer took a galley of
            type and scrambled it to make a
            type specimen book. It has survived
            not only five centuries, but also
            the leap into electronic
            typesetting, remaining essentially
            unchanged. It was popularised in
            the 1960s with the release of
            Letraset sheets containing Lorem
            Ipsum passages, and more recently
          </Typography>
        </Box>
        <Box
          sx={{
            padding: '0 20px',
          }}
        >
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: item.imgFirst ? 'row' : 'row-reverse',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <Box
                  component="img"
                  src={item.img}
                  alt={`Image ${index + 1}`}
                  sx={{ width: '40%', height: 'auto', marginRight: item.imgFirst ? '20px' : '0', marginLeft: item.imgFirst ? '0' : '20px' }}
                />
                <Typography variant="body1" sx={{ width: '50%', color: '#b0b0ff' }}>
                  {item.text}
                </Typography>
              </Box>
              {index < items.length - 1 && (
                <Divider sx={{ width: '80%', backgroundColor: 'white', marginBottom: '20px' }} />
              )}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AboutUsPage;
