import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, grey } from '@mui/material/colors';
import logoImage from './static/Logo_txt.png';
import backgroundImage from './static/Bg.png'; // Імпортуємо задній фон
import leftIcon from './static/Frame 1509.svg'; // Імпортуємо іконку для боків
import rightIcon from './static/Frame 1508.svg'; // Імпортуємо іконку для боків
import coinIcon from './static/SPHR.png'; // Імпортуємо іконку монети

// Імпортуємо аватарки за замовчуванням
import defaultAvatar1 from './static/gold_ava.svg';
import defaultAvatar2 from './static/ser_ava.png';
import defaultAvatar3 from './static/bronse_avas.svg';
import defaultAvatar4 from './static/all_ava.svg';

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: grey,
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

const players = [
  { id: 1, name: 'Oleg Efymenko', avatar: '', score: '1.3274' },
  { id: 2, name: 'mdtaimur667', avatar: '', score: '1.3274' },
  { id: 3, name: 'poatoct', avatar: '', score: '1.3274' },
  { id: 4, name: 'k4tsar0s._', avatar: '', score: '1.3274' },
  { id: 5, name: 'william.baum7', avatar: '', score: '1.3274' },
  { id: 6, name: '2k.3lias7', avatar: '', score: '1.3274' },
  // Додаємо більше гравців для тестування прокручування
  ...Array.from({ length: 94 }, (_, index) => ({
    id: index + 7,
    name: `player${index + 7}`,
    avatar: '',
    score: (1.3274 - (index + 1) * 0.01).toFixed(4),
  })),
];

const getBorderColor = (index) => {
  switch (index) {
    case 0:
      return 'gold';
    case 1:
      return 'silver';
    case 2:
      return '#cd7f32'; // bronze
    default:
      return 'grey';
  }
};

const getDefaultAvatar = (index) => {
  switch (index) {
    case 0:
      return defaultAvatar1;
    case 1:
      return defaultAvatar2;
    case 2:
      return defaultAvatar3;
    default:
      return defaultAvatar4;
  }
};

const LeaderBoardPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="sm"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          color: 'white',
          textAlign: 'center',
          overflow: 'hidden',
          padding: 0,
          margin: 0,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 4,
        }}
      >
        <Box
          component="img"
          src={logoImage}
          alt="Logo"
          sx={{
            width: '140px',
            height: 'auto',
            marginTop: '20px',
          }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <Box component="img" src={leftIcon} alt="Left Icon" sx={{ width: '54px', height: '24px', marginRight: '10px' }} />
          <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 'bold' }}>
            LeaderBoard
          </Typography>
          <Box component="img" src={rightIcon} alt="Right Icon" sx={{ width: '54px', height: '24px', marginLeft: '10px' }} />
        </Box>
        <Typography variant="body1" sx={{ marginTop: '10px', fontSize: '16px' }}>
          Do the tasks and earn additional SPHR
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <Typography variant="body2" sx={{ marginRight: '20px', fontSize: '16px', fontWeight: 'bold' }}>
            COIN MINERS
          </Typography>
          <Typography variant="body2" sx={{ marginRight: '20px', fontSize: '16px', color: 'gray' }}>
            REFERRALS
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '16px', color: 'gray' }}>
            COMMUNITIES
          </Typography>
        </Box>
        <Box
          sx={{
            width: '90%',
            marginTop: '20px',
            padding: '20px',
            height: '60vh', // Обмежуємо висоту контейнера з лідерами
            overflowY: 'auto', // Додаємо вертикальний скрол
            '&::-webkit-scrollbar': {
              display: 'none', // Ховаємо скроллбар для браузерів на базі WebKit
            },
            '-ms-overflow-style': 'none', // Ховаємо скроллбар для IE і Edge
            'scrollbar-width': 'none', // Ховаємо скроллбар для Firefox
          }}
        >
          {players.slice(0, 100).map((player, index) => (
            <Box
              key={player.id}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '8px',
                padding: '10px 20px',
                marginBottom: '10px',
                border: `2px solid ${getBorderColor(index)}`,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px' }}>
                  {index + 1}
                </Typography>
                <Box
                  component="img"
                  src={player.avatar || getDefaultAvatar(index)}
                  alt={player.name}
                  sx={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                />
                <Typography variant="body1">{player.name}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '5px' }}>
                  {player.score}
                </Typography>
                <Box component="img" src={coinIcon} alt="Coin Icon" sx={{ width: '20px', height: '20px' }} />
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LeaderBoardPage;
