import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import './Sphere.css';
import Sphere from './static/Sphere1.png';
import micro from './static/voice_icon.png';
import microbg from './static/micro_bg.png';
import coin_sphr from './static/SPHR.png';
import sphereGlow from './static/Sphere Glow.png'; // Імпортуємо зображення заднього фону

const SpherePage = ({ balance, setBalance, userId }) => {

  console.log(balance)

  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [isMiningVoice, setIsMiningVoice] = useState(false);
  const [clickEffects, setClickEffects] = useState([]);
  const [clickEffect, setClickEffect] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const [isPressed, setIsPressed] = useState(false);
  const audioUpdateInterval = useRef(null);
  const containerRef = useRef(null);
  const sphereRef = useRef(null);
  const holdTimeout = useRef(null);

  const initializeAudio = async () => {
    if (!audioContext) {
      const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
      setAudioContext(newAudioContext);

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const source = newAudioContext.createMediaStreamSource(stream);
        const newAnalyser = newAudioContext.createAnalyser();
        source.connect(newAnalyser);
        newAnalyser.fftSize = 1024;
        setAnalyser(newAnalyser);
        startAudioProcessing(newAnalyser);
      } catch (err) {
        console.error('Помилка доступу до мікрофона', err);
      }
    }
  };

  const startAudioProcessing = (analyser) => {
    if (audioUpdateInterval.current) return;
    const dataArray = new Uint8Array(analyser.fftSize);

    audioUpdateInterval.current = setInterval(() => {
      analyser.getByteTimeDomainData(dataArray);
      const max = Math.max(...dataArray);
      if (max > 128) {
        if (!isMiningVoice) {
          setIsMiningVoice(true);
        }

        if (balance.coins >= 1) {
          setBalance(prev => ({
            ...prev,
            coins: prev.coins - 1,
            nrg: prev.nrg + 0.01
          }));
        } else {
          setIsMiningVoice(false);
          stopAudioProcessing();
        }
      } else {
        setIsMiningVoice(false);
      }
    }, 100);

    return () => {
      clearInterval(audioUpdateInterval.current);
      audioUpdateInterval.current = null;
    };
  };

  const stopAudioProcessing = () => {
    clearInterval(audioUpdateInterval.current);
    audioUpdateInterval.current = null;
  };

  useEffect(() => {
    return () => clearInterval(audioUpdateInterval.current);
  }, []);

  useEffect(() => {
    if (isMiningVoice) {
      const interval = setInterval(() => {
        const nrgEarned = 0.01;
        setBalance(prev => ({ ...prev, nrg: prev.nrg + nrgEarned }));

        // Add click effect for voice mining
        const rect = containerRef.current.getBoundingClientRect();
        const x = rect.width / 2;
        const y = rect.height / 2;

        setClickEffects((effects) => [...effects, { x, y, time: Date.now() }]);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isMiningVoice]);

  useEffect(() => {
    const interval = setInterval(() => {
      setClickEffects((effects) => effects.filter((effect) => Date.now() - effect.time < 1500));
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const handleInteractionStart = (e) => {
    e.preventDefault();
    setIsPressed(true);

    if (sphereRef.current && sphereRef.current.contains(e.target)) {
      const rect = sphereRef.current.getBoundingClientRect();
      const x = (e.clientX || e.touches[0].clientX) - rect.left;
      const y = (e.clientY || e.touches[0].clientY) - rect.top;

      setClickEffects((effects) => [...effects, { x, y, time: Date.now() }]);
      setClickPosition({ x, y });

      if (navigator.vibrate) {
        navigator.vibrate(40);
      }

      setClickEffect(true);

      holdTimeout.current = setTimeout(() => {
        setClickEffect(false);
      }, 200);

      if (balance.coins >= 1) {
        fetch(`https://api.minex.space/balance/${userId}/mine`, {
          method: 'POST',
        })
          .then(response => response.json())
          .then(data => setBalance(data));
      }
    }
  };

  const handleInteractionEnd = () => {
    clearTimeout(holdTimeout.current);
    setIsPressed(false);
    setClickEffect(false);
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: 'none',
        overflow: 'hidden',
        marginTop: '15vh',
      }}
      onTouchStart={handleInteractionStart}
      onTouchEnd={handleInteractionEnd}
      tabIndex={1}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '5vh',
        }}
      >
        <Box component="img" src={coin_sphr} alt="Icon" sx={{ width: '10vw', height: 'auto', marginRight: '2vw' }} />
        <Typography variant="body1"
         sx={{ color: '#60D1FF',
          fontSize: '7vw',
           fontFamily: 'Orbitron, sans-serif',
           fontWeight: 'bold'}}>
          {balance.nrg.toFixed(2)}
        </Typography>
      </Box>
      <Box
        ref={sphereRef}
        className={`sphere-container ${clickEffect || isMiningVoice ? 'clicked' : ''}`}
        sx={{
          width: '60vw',
          height: '60vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          userSelect: 'none',
          transform: isPressed ? 'scale(0.9)' : 'scale(1)',
          transition: 'transform 0.2s ease',
        }}
        style={{
          '--click-x': `${clickPosition.x}px`,
          '--click-y': `${clickPosition.y}px`,
        }}
      >
        <Box
          className="sphere"
          sx={{
            backgroundImage: `url(${Sphere})`,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            transition: `transform 0.6s ease, box-shadow 0.6s ease`,
            userSelect: 'none',
            border: 'none',
            outline: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
        </Box>
        <Box
          component="img"
          src={sphereGlow}
          alt="Sphere Glow"
          sx={{
            position: 'absolute',
            width: '90vw', // Збільшуємо розмір світіння
            height: '90vw', // Збільшуємо розмір світіння
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: -1,
            pointerEvents: 'none',
          }}
        />
      </Box>

      <IconButton
        sx={{
          backgroundImage: `url(${microbg})`,
          width: '50vw',
          height: '5vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '8vh',
          padding: '1vh',
          cursor: 'pointer',
          borderRadius: 0,
        }}
        onClick={initializeAudio}
      >
        <Typography variant="body1" sx={{ color: 'white', marginRight: '2vw', fontFamily: 'Orbitron, sans-serif', fontWeight: 'bold'}}>
          Voice
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box component="img" src={micro} alt="Icon" sx={{ width: '9vw', height: '5vh' }} />
        </Box>
        <Typography variant="body1" sx={{ color: 'white', marginLeft: '2vw', fontFamily: 'Orbitron, sans-serif', fontWeight: 'bold' }}>
          Mining
        </Typography>
      </IconButton>

      {clickEffects.map((effect, index) => (
        <Box
          key={index}
          className="click-effect-text"
          sx={{
            top: `${effect.y + 50}px`,
            left: `${effect.x + 50}px`,
          }}
        >
          0.01
        </Box>
      ))}
    </Box>
  );
};

export default SpherePage;
